import '../css-build/main.css';
import { Elm } from '../elm/Main.elm';
import { getBlurParamsForSection, getAllTeamMembersWidth, langFromURL } from './utils';

var oldErrorHandler = window.onerror || function () {};
window.onerror = function (message, source, lineno, colno, error) {
  // handleError(message, source, lineno, colno, error);
  oldErrorHandler(message, source, lineno, colno, error);
  return true;
};

const TYPING_ANIMATION_SPEED_MS = 25; // per char speed
const TYPING_ANIMATION_INITIAL_DELAY_MS = 100;
const MEMBERS_ANIMATION_DURATION = 120000;
let charIndex = 0;
let shouldStopTyping = false;

let startX;
let mouseDown = false;

function type() {
  const typedTextSpan = document.querySelector('.hero-section__header__typed-text');
  const hiddenTextSpan = document.querySelector('.hero-section__header__hidden-text');
  if (hiddenTextSpan && typedTextSpan) {
    const text = hiddenTextSpan.textContent;
    const cursorSpan = document.querySelector('.hero-section__header__cursor');
    if (charIndex < text.length && !shouldStopTyping) {
      if (!cursorSpan.classList.contains('typing')) {
        cursorSpan.classList.add('typing');
      }
      typedTextSpan.textContent += text.charAt(charIndex);
      charIndex++;
      setTimeout(type, TYPING_ANIMATION_SPEED_MS);
    } else {
      //  remove cursor
      setTimeout(() => {
        cursorSpan.classList.add('hide');
      }, TYPING_ANIMATION_SPEED_MS);
    }
  }
}

function blurTransition() {
  const labsHomeSectionContent = document.querySelectorAll('.home-section__content');
  Array.from(labsHomeSectionContent).forEach((lhsc) => {
    const [filter, opacity] = getBlurParamsForSection(lhsc);
    lhsc.style.filter = filter;
    lhsc.style.opacity = opacity;
  });
}

let preScrollY = 0;
function autoCompleteTyping(marketingHome) {
  if (preScrollY > marketingHome.scrollTop) {
    const about = document.getElementById('about');
    // scrolling up & about section is within the view, meaning intro section is next up in view
    if (about.getBoundingClientRect().top > 0) {
      document.querySelector('.hero-section__header__typed-text').textContent = document.querySelector(
        '.hero-section__header__hidden-text'
      ).textContent;
      shouldStopTyping = true;
    }
  }
  preScrollY = marketingHome.scrollTop;
}

function updateNavbar() {
  let activeSection;
  const labsHomeSections = document.querySelectorAll('.home-section');
  Array.from(labsHomeSections).forEach((lhs) => {
    const rect = lhs.getBoundingClientRect();
    if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
      activeSection = '#' + lhs.id;
      const navBars = document.querySelectorAll('.nav-item');
      // make sure the nav bar corresponding to the active section has 'active' class
      // the rest of the nav bars do not have 'active' class
      Array.from(navBars).forEach((nav) => {
        if (nav.classList.contains(activeSection)) {
          if (!nav.classList.contains('active')) {
            nav.classList.add('active');
          }
        } else if (nav.classList.contains('active')) {
          nav.classList.remove('active');
        }
      });
      // since this effects the scroll position, so execute in the end
      const location = window.location.toString().split('#')[0];
      // only update the window location hash when there is new one
      if (window.location.hash !== `#${lhs.id}`) {
        history.replaceState(null, null, location + '#' + lhs.id);
      }
    }
  });
}

function startTeamMembersAnimation(membersPhotos) {
  Array.from(membersPhotos).forEach((m) => {
    m.animate([{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }, { transform: `matrix(1, 0, 0, 1, -${m.clientWidth}, 0)` }], {
      duration: MEMBERS_ANIMATION_DURATION,
      iterations: Infinity,
      easing: 'linear',
    });
  });
}

function getTranslateValues(element) {
  const style = window.getComputedStyle(element);
  const matrix = style.transform || style.webkitTransform || style.mozTransform;
  const matrixValues = matrix.match(/matrix.*\((.+)\)/) ? matrix.match(/matrix.*\((.+)\)/)[1].split(', ') : 0;
  return parseFloat(matrixValues[4]);
}

function handlePointerMoveMembers(e, membersSection) {
  if (!mouseDown) {
    handleMouseHoverTeamMembers(e);
    return;
  }
  e.preventDefault();
  handleMouseDragTeamMembers(e, membersSection);
}

function handlePointerDownMembers(e, membersSection) {
  e.preventDefault();
  mouseDown = true;
  startX = e.offsetX;
  membersSection.classList.add('is-dragging');
}

function handleMouseHoverTeamMembers(e) {
  e.preventDefault();
  Array.from(document.querySelectorAll('.members')).forEach((m) => {
    if (m.getAnimations()[0]) {
      m.getAnimations()[0].commitStyles();
      m.getAnimations()[0].cancel();
    }
  });
}

function handleScrollMembers(e, membersSection) {
  e.preventDefault();
  const members = document.querySelector('.members');
  const newNode = members.cloneNode(true);
  if (
    members.getBoundingClientRect().left <=
    window.innerWidth - getAllTeamMembersWidth(document.querySelectorAll('.members'))
  ) {
    // this means it reaches the end
    membersSection.appendChild(newNode);
  }
}

let inserted = false;
function handleMouseDragTeamMembers(e, membersSection) {
  e.preventDefault();
  const scroll = e.offsetX - startX;
  const members = document.querySelector('.members');
  // cloning the lastest node is necessary to ensure the new div rendering without rendering lag
  const newNode = members.cloneNode(true);
  const prev = getTranslateValues(members);
  let translateX = prev + scroll;
  if (translateX < window.innerWidth - getAllTeamMembersWidth(document.querySelectorAll('.members'))) {
    if (!inserted) {
      membersSection.appendChild(newNode);
      inserted = true;
    }
    translateX += newNode.clientWidth;
  } else if (translateX > 0) {
    if (!inserted) {
      membersSection.insertAdjacentElement('afterbegin', newNode);
      inserted = true;
    }
    // adjust the translateX by client width so the translation is negative
    translateX -= newNode.clientWidth;
  }
  // need to re-query the members due we are insertting nodes on both edges
  Array.from(document.querySelectorAll('.members')).forEach((m) => {
    m.style.transform = `matrix(1, 0, 0 ,1, ${translateX}, 0)`;
  });
  // add throttle here to impprove performance
  setTimeout(() => {
    inserted = false;
  }, 1000);
}

function handlePointerUpMembers(e, membersSection) {
  e.preventDefault();
  mouseDown = false;
  membersSection.classList.remove('is-dragging');
}

function handlePointerLeaveMembers(e) {
  e.preventDefault();
  // here needs to query the members div to get the latest width instead of reusing the membersPhotos var
  const currentMembersPhotosArray = document.querySelectorAll('.members-section .members');
  const member = document.querySelector('.members');
  const translateX = getTranslateValues(member);

  let normalizedTranslateX = translateX;
  if (normalizedTranslateX > 0) {
    while (normalizedTranslateX > 0) {
      normalizedTranslateX -= member.clientWidth;
    }
  } else if (normalizedTranslateX < -member.clientWidth) {
    while (normalizedTranslateX < -member.clientWidth) {
      normalizedTranslateX += member.clientWidth;
    }
  }
  const iterationStart = -normalizedTranslateX / member.clientWidth;
  const transform = `matrix(1, 0, 0, 1, -${member.clientWidth}, 0)`;
  Array.from(currentMembersPhotosArray).forEach((m) => {
    m.animate([{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }, { transform }], {
      duration: MEMBERS_ANIMATION_DURATION,
      iterations: Infinity,
      easing: 'linear',
      iterationStart,
    });
  });
}

window.addEventListener('DOMContentLoaded', function () {
  setTimeout(type, TYPING_ANIMATION_INITIAL_DELAY_MS);
  const url = new URL(window.location);

  let buildHref = (href) => href;
  let stripHref = (href) => href;
  let mountPath = window.BUILD_MOUNT_PATH || process.env.MOUNT_PATH || '';
  if (mountPath) {
    let mountRegex = new RegExp(`^/${mountPath}`);

    stripHref = (href) => {
      let url = new URL(href);
      url.pathname = url.pathname.replace(mountRegex, '');
      return url.href;
    };

    buildHref = (href) => {
      let url = new URL(href);
      url.pathname = mountPath + url.pathname;
      return url.href;
    };
  }

  let isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1;

  // Funny quirk on Firefox, vendor is empty string
  // so the and returns the empty string -_-
  isSafari = isSafari === '' ? false : isSafari;

  console.log(isSafari);

  const app = Elm.Main.init({
    node: document.getElementById('root') || document.getElementById('main'), // no pre-rendered // pre-rendered
    flags: {
      language: langFromURL(url, window.navigator.language),
      path: stripHref(window.location.href),
      userAgent: navigator.userAgent,
      isSafari: isSafari,
    },
  });

  function findParent(tagname, el) {
    if (!el) {
      return null;
    }

    if ((el.nodeName || el.tagName).toLowerCase() === tagname.toLowerCase()) {
      return el;
    }

    return findParent(tagname, el.parentNode);
  }

  document.addEventListener('click', (e) => {
    e = e || event;
    var from = findParent('a', e.target || e.srcElement);
    if (from && from.href && !from.rel && !from.href.startsWith('blob:')) {
      history.pushState({}, '', buildHref(from.href));
      e.preventDefault();
      app.ports.onUrlChange.send(from.href);
    }
  });

  function addTeamMembersEvents(membersSection) {
    membersSection.addEventListener('pointerleave', handlePointerLeaveMembers);
    membersSection.addEventListener('pointerdown', (e) => handlePointerDownMembers(e, membersSection));
    membersSection.addEventListener('pointerup', (e) => handlePointerUpMembers(e, membersSection));
    membersSection.addEventListener('pointermove', (e) => handlePointerMoveMembers(e, membersSection));
    membersSection.addEventListener('scroll', (e) => handleScrollMembers(e, membersSection));
  }

  // scroll on labs home page
  const marketingHome = document.getElementById('marketing-home');
  if (marketingHome) {
    marketingHome.addEventListener('scroll', () => {
      blurTransition();
      updateNavbar();
      autoCompleteTyping(marketingHome);
    });

    // start animate
    const membersSection = document.querySelector('.members-section');
    const membersPhotos = document.querySelectorAll('.members-section .members');
    if (membersSection && membersPhotos) {
      if (window.innerWidth > 576) {
        startTeamMembersAnimation(membersPhotos);
        addTeamMembersEvents(membersSection);
      }
    }
  }

  // Inform app of browser navigation (the BACK and FORWARD buttons)
  let statePopped = false;
  window.addEventListener('popstate', () => {
    statePopped = true;
    app.ports.onUrlChange.send(location.href);
  });

  app.ports.log.subscribe((msg) => {
    console.error(msg);
  });

  app.ports.scrollTop.subscribe(() => {
    if (!statePopped) {
      window.scrollTo(0, 0);
    } else {
      statePopped = false;
    }
  });

  app.ports.setTitle.subscribe((title) => {
    document.title = title;
  });

  app.ports.copy.subscribe((text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  });
});
