(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.B.cf === region.u.cf)
	{
		return 'on line ' + region.B.cf;
	}
	return 'on lines ' + region.B.cf + ' through ' + region.u.cf;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.iR,
		impl.iG,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		id: func(record.id),
		iF: record.iF,
		iq: record.iq
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.id;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.iF;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.iq) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.iR,
		impl.iG,
		function(sendToApp, initialModel) {
			var view = impl.iT;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.h3,
		impl.iR,
		impl.iG,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.e6 && impl.e6(sendToApp)
			var view = impl.iT;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hr);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.iJ) && (_VirtualDom_doc.title = title = doc.iJ);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.il;
	var onUrlRequest = impl.im;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		e6: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gL === next.gL
							&& curr.f6 === next.f6
							&& curr.gH.a === next.gH.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		h3: function(flags)
		{
			return A3(impl.h3, flags, _Browser_getUrl(), key);
		},
		iT: impl.iT,
		iR: impl.iR,
		iG: impl.iG
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { h$: 'hidden', hy: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { h$: 'mozHidden', hy: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { h$: 'msHidden', hy: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { h$: 'webkitHidden', hy: 'webkitvisibilitychange' }
		: { h$: 'hidden', hy: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		gU: _Browser_getScene(),
		hb: {
			hf: _Browser_window.pageXOffset,
			hh: _Browser_window.pageYOffset,
			he: _Browser_doc.documentElement.clientWidth,
			f5: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		he: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		f5: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			gU: {
				he: node.scrollWidth,
				f5: node.scrollHeight
			},
			hb: {
				hf: node.scrollLeft,
				hh: node.scrollTop,
				he: node.clientWidth,
				f5: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			gU: _Browser_getScene(),
			hb: {
				hf: x,
				hh: y,
				he: _Browser_doc.documentElement.clientWidth,
				f5: _Browser_doc.documentElement.clientHeight
			},
			hQ: {
				hf: x + rect.left,
				hh: y + rect.top,
				he: rect.width,
				f5: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// SEND REQUEST

var _Http_toTask = F2(function(request, maybeProgress)
{
	return _Scheduler_binding(function(callback)
	{
		var xhr = new XMLHttpRequest();

		_Http_configureProgress(xhr, maybeProgress);

		xhr.addEventListener('error', function() {
			callback(_Scheduler_fail($elm$http$Http$NetworkError));
		});
		xhr.addEventListener('timeout', function() {
			callback(_Scheduler_fail($elm$http$Http$Timeout));
		});
		xhr.addEventListener('load', function() {
			callback(_Http_handleResponse(xhr, request.fY.a));
		});

		try
		{
			xhr.open(request.ie, request.iS, true);
		}
		catch (e)
		{
			return callback(_Scheduler_fail($elm$http$Http$BadUrl(request.iS)));
		}

		_Http_configureRequest(xhr, request);

		var body = request.hr;
		xhr.send($elm$http$Http$Internal$isStringBody(body)
			? (xhr.setRequestHeader('Content-Type', body.a), body.b)
			: body.a
		);

		return function() { xhr.abort(); };
	});
});

function _Http_configureProgress(xhr, maybeProgress)
{
	if (!$elm$core$Maybe$isJust(maybeProgress))
	{
		return;
	}

	xhr.addEventListener('progress', function(event) {
		if (!event.lengthComputable)
		{
			return;
		}
		_Scheduler_rawSpawn(maybeProgress.a({
			ht: event.loaded,
			hu: event.total
		}));
	});
}

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.f4; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}

	xhr.responseType = request.fY.b;
	xhr.withCredentials = request.iW;

	$elm$core$Maybe$isJust(request.g5) && (xhr.timeout = request.g5.a);
}


// RESPONSES

function _Http_handleResponse(xhr, responseToResult)
{
	var response = _Http_toResponse(xhr);

	if (xhr.status < 200 || 300 <= xhr.status)
	{
		response.body = xhr.responseText;
		return _Scheduler_fail($elm$http$Http$BadStatus(response));
	}

	var result = responseToResult(response);

	if ($elm$core$Result$isOk(result))
	{
		return _Scheduler_succeed(result.a);
	}
	else
	{
		response.body = xhr.responseText;
		return _Scheduler_fail(A2($elm$http$Http$BadPayload, result.a, response));
	}
}

function _Http_toResponse(xhr)
{
	return {
		iS: xhr.responseURL,
		iD: { hA: xhr.status, id: xhr.statusText },
		f4: _Http_parseHeaders(xhr.getAllResponseHeaders()),
		hr: xhr.response
	};
}

function _Http_parseHeaders(rawHeaders)
{
	var headers = $elm$core$Dict$empty;

	if (!rawHeaders)
	{
		return headers;
	}

	var headerPairs = rawHeaders.split('\u000d\u000a');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf('\u003a\u0020');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}

	return headers;
}


// EXPECTORS

function _Http_expectStringResponse(responseToResult)
{
	return {
		$: 0,
		b: 'text',
		a: responseToResult
	};
}

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		b: expect.b,
		a: function(response) {
			var convertedResponse = expect.a(response);
			return A2($elm$core$Result$map, func, convertedResponse);
		}
	};
});


// BODY

function _Http_multipart(parts)
{


	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}

	return $elm$http$Http$Internal$FormDataBody(formData);
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.$8) { flags += 'm'; }
	if (options.hv) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.x) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.C);
		} else {
			var treeLen = builder.x * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.D) : builder.D;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.x);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.C) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.C);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{D: nodeList, x: (len / $elm$core$Array$branchFactor) | 0, C: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {f2: fragment, f6: host, eU: path, gH: port_, gL: protocol, gM: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Page$Home = {$: 0};
var $author$project$Main$SetTimeZone = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Tick = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Marketing$Home$emptyState = {bW: '#intro', cS: _List_Nil, cX: $elm$core$Maybe$Nothing};
var $author$project$Marketing$Thesis$About = 0;
var $author$project$Marketing$Thesis$Empty = 2;
var $author$project$Marketing$Thesis$emptyState = {fB: $elm$core$Maybe$Nothing, b0: 0, b5: '', aV: 2, cS: _List_Nil, dc: $elm$core$Maybe$Nothing};
var $author$project$Strings$Translations$En = 0;
var $author$project$Strings$Translations$Es = 1;
var $author$project$Strings$Translations$getLnFromCode = function (code) {
	switch (code) {
		case 'en':
			return 0;
		case 'es':
			return 1;
		default:
			return 0;
	}
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Main$DelayScroll = function (a) {
	return {$: 3, a: a};
};
var $author$project$Marketing$Home$AllJobsResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Marketing$Home$ForSelf = $elm$core$Basics$identity;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$http$Http$Internal$EmptyBody = {$: 0};
var $elm$http$Http$emptyBody = $elm$http$Http$Internal$EmptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadPayload = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Internal$FormDataBody = function (a) {
	return {$: 2, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$Internal$isStringBody = function (body) {
	if (body.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = _Http_expectStringResponse;
var $elm$http$Http$expectJson = function (decoder) {
	return $elm$http$Http$expectStringResponse(
		function (response) {
			var _v0 = A2($elm$json$Json$Decode$decodeString, decoder, response.hr);
			if (_v0.$ === 1) {
				var decodeError = _v0.a;
				return $elm$core$Result$Err(
					$elm$json$Json$Decode$errorToString(decodeError));
			} else {
				var value = _v0.a;
				return $elm$core$Result$Ok(value);
			}
		});
};
var $elm$http$Http$Internal$Request = $elm$core$Basics$identity;
var $elm$http$Http$request = $elm$core$Basics$identity;
var $elm$http$Http$get = F2(
	function (url, decoder) {
		return $elm$http$Http$request(
			{
				hr: $elm$http$Http$emptyBody,
				fY: $elm$http$Http$expectJson(decoder),
				f4: _List_Nil,
				ie: 'GET',
				g5: $elm$core$Maybe$Nothing,
				iS: url,
				iW: false
			});
	});
var $author$project$LeverJobsApi$Models$Job = F3(
	function (categories, title, url) {
		return {hw: categories, iJ: title, iS: url};
	});
var $author$project$LeverJobsApi$Models$JobCategories = function (location) {
	return {h8: location};
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$LeverJobsApi$Decoders$jobCategoriesDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$LeverJobsApi$Models$JobCategories,
	A2($elm$json$Json$Decode$field, 'location', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$LeverJobsApi$Decoders$jobDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$LeverJobsApi$Models$Job,
	A2($elm$json$Json$Decode$field, 'categories', $author$project$LeverJobsApi$Decoders$jobCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'hostedUrl', $elm$json$Json$Decode$string));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$LeverJobsApi$Decoders$jobsResponseDecoder = $elm$json$Json$Decode$list($author$project$LeverJobsApi$Decoders$jobDecoder);
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$toTask = function (_v0) {
	var request_ = _v0;
	return A2(_Http_toTask, request_, $elm$core$Maybe$Nothing);
};
var $elm$http$Http$send = F2(
	function (resultToMessage, request_) {
		return A2(
			$elm$core$Task$attempt,
			resultToMessage,
			$elm$http$Http$toTask(request_));
	});
var $author$project$Marketing$Home$loadJobs = function () {
	var jobsRequestHttpGet = A2($elm$http$Http$get, 'https://api.lever.co/v0/postings/compound-2?mode=json', $author$project$LeverJobsApi$Decoders$jobsResponseDecoder);
	return A2(
		$elm$http$Http$send,
		A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$Marketing$Home$AllJobsResponse),
		jobsRequestHttpGet);
}();
var $author$project$Marketing$Thesis$AllVideosDataResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Marketing$Thesis$ForSelf = $elm$core$Basics$identity;
var $author$project$Marketing$Thesis$Models$SheetsApiResponse = function (values) {
	return {ha: values};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Marketing$Thesis$Decoders$sheetsApiResponseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'values',
	$elm$json$Json$Decode$list(
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$succeed($author$project$Marketing$Thesis$Models$SheetsApiResponse));
var $author$project$Marketing$Thesis$loadMetadata = function () {
	var sheetsRequestHttpGet = A2($elm$http$Http$get, 'https://sheets.googleapis.com/v4/spreadsheets/1ODPVAslVwnKKCs-1QwKqgjFstwThHJFw1YiX6FbEokc/values/Sheet1!A2%3AO50?key=AIzaSyCXNOy72i0SkhEmltOTv7JnZsCv2ulUG1c', $author$project$Marketing$Thesis$Decoders$sheetsApiResponseDecoder);
	return A2(
		$elm$http$Http$send,
		A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$Marketing$Thesis$AllVideosDataResponse),
		sheetsRequestHttpGet);
}();
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$MarketingHomeMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Marketing$Home$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gD;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$Main$marketingHomeTranslator = $author$project$Marketing$Home$translator(
	{gD: $author$project$Main$MarketingHomeMsg});
var $author$project$Main$MarketingThesisMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Marketing$Thesis$translator = F2(
	function (_v0, msg) {
		var onInternalMessage = _v0.gD;
		var internal = msg;
		return onInternalMessage(internal);
	});
var $author$project$Main$marketingThesisTranslator = $author$project$Marketing$Thesis$translator(
	{gD: $author$project$Main$MarketingThesisMsg});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Main$scrollTop = _Platform_outgoingPort(
	'scrollTop',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Main$setTitle = _Platform_outgoingPort('setTitle', $elm$json$Json$Encode$string);
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Strings$Translations$site_title = function (lang) {
	if (!lang) {
		return 'Compound Labs';
	} else {
		return 'Puerta de enlace compuesta';
	}
};
var $author$project$Page$buildTitle = function (userLanguage) {
	return $author$project$Strings$Translations$site_title(userLanguage);
};
var $author$project$Page$title = F2(
	function (userLanguage, _v0) {
		return $author$project$Page$buildTitle(userLanguage);
	});
var $author$project$Main$doTransitionPage = function (model) {
	var newPage = model.a$;
	var maybeScrollCmd = A2(
		$elm$core$Task$perform,
		$author$project$Main$DelayScroll,
		A2(
			$elm$core$Task$andThen,
			function (_v3) {
				return $elm$core$Task$succeed(newPage);
			},
			$elm$core$Process$sleep(25)));
	var _v0 = function () {
		if (!newPage.$) {
			return _Utils_Tuple2(
				model,
				A2($elm$core$Platform$Cmd$map, $author$project$Main$marketingHomeTranslator, $author$project$Marketing$Home$loadJobs));
		} else {
			if (newPage.a.$ === 1) {
				var _v2 = newPage.a;
				var oldModel = model.aI;
				var nuThesisModel = _Utils_update(
					oldModel,
					{fB: $elm$core$Maybe$Nothing});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: nuThesisModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$marketingThesisTranslator, $author$project$Marketing$Thesis$loadMetadata));
			} else {
				var episodeNumber = newPage.a.a;
				var oldModel = model.aI;
				var nuThesisModel = _Utils_update(
					oldModel,
					{
						fB: $elm$core$Maybe$Just(episodeNumber)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: nuThesisModel}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Main$scrollTop(0),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$marketingThesisTranslator, $author$project$Marketing$Thesis$loadMetadata)
							])));
			}
		}
	}();
	var nextModel = _v0.a;
	var nextModelCmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			nextModel,
			{a$: newPage}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					maybeScrollCmd,
					$author$project$Main$setTitle(
					A2($author$project$Page$title, model.bU, newPage)),
					nextModelCmd
				])));
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bh: frag, bk: params, a8: unvisited, db: value, bs: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.a8;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.db);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.db);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.eU),
					$elm$url$Url$Parser$prepareQuery(url.gM),
					url.f2,
					$elm$core$Basics$identity)));
	});
var $author$project$Page$Thesis = function (a) {
	return {$: 1, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$fragment = function (toFrag) {
	return function (_v0) {
		var visited = _v0.bs;
		var unvisited = _v0.a8;
		var params = _v0.bk;
		var frag = _v0.bh;
		var value = _v0.db;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					toFrag(frag)))
			]);
	};
};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bs;
		var unvisited = _v0.a8;
		var params = _v0.bk;
		var frag = _v0.bh;
		var value = _v0.db;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bs;
			var unvisited = _v1.a8;
			var params = _v1.bk;
			var frag = _v1.bh;
			var value = _v1.db;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bs;
		var unvisited = _v0.a8;
		var params = _v0.bk;
		var frag = _v0.bh;
		var value = _v0.db;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Page$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Page$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Page$Thesis,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('thesis'),
				$elm$url$Url$Parser$fragment($elm$core$Basics$identity)))
		]));
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Page$getPage = function (location) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$Home,
		A2($elm$url$Url$Parser$parse, $author$project$Page$parser, location));
};
var $author$project$Main$transitionPage = F2(
	function (model, url) {
		var newPage = function () {
			if (!url.$) {
				var location = url.a;
				return $author$project$Page$getPage(location);
			} else {
				return model.a$;
			}
		}();
		return $author$project$Main$doTransitionPage(
			_Utils_update(
				model,
				{a$: newPage}));
	});
var $author$project$Main$transitionTo = F2(
	function (url, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = A2($author$project$Main$transitionPage, model, url);
		var newModel = _v1.a;
		var pageCmd = _v1.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[pageCmd, cmd])));
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Main$init = function (_v0) {
	var path = _v0.eU;
	var language = _v0.ee;
	var isSafari = _v0.bF;
	var model = {
		dz: $elm$core$Maybe$Nothing,
		dA: $elm$time$Time$utc,
		cS: _List_Nil,
		bF: isSafari,
		ch: $author$project$Marketing$Home$emptyState,
		aI: $author$project$Marketing$Thesis$emptyState,
		c0: false,
		gy: $elm$core$Maybe$Nothing,
		a$: $author$project$Page$Home,
		bU: $author$project$Strings$Translations$getLnFromCode(language)
	};
	return A2(
		$author$project$Main$transitionTo,
		$elm$url$Url$fromString(path),
		_Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Main$Tick, $elm$time$Time$now),
						A2($elm$core$Task$perform, $author$project$Main$SetTimeZone, $elm$time$Time$here)
					]))));
};
var $author$project$Main$UrlChange = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {gK: processes, g2: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.gK;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.g2);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Main$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $author$project$Shared$Utils$Time$seconds = 1;
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, (1000000.0 * 1.0) * $author$project$Shared$Utils$Time$seconds, $author$project$Main$Tick),
				$author$project$Main$onUrlChange(
				A2($elm$core$Basics$composeR, $elm$url$Url$fromString, $author$project$Main$UrlChange))
			]));
};
var $author$project$Main$copy = _Platform_outgoingPort('copy', $elm$json$Json$Encode$string);
var $author$project$Shared$Utils$Console$log = _Platform_outgoingPort('log', $elm$json$Json$Encode$string);
var $author$project$Main$maybeScroll = function (page) {
	return $author$project$Main$scrollTop(0);
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Shared$Utils$Console$error = function (err) {
	return $author$project$Shared$Utils$Console$log('Error: ' + err);
};
var $author$project$Shared$Utils$Http$showError = function (err) {
	switch (err.$) {
		case 0:
			var url = err.a;
			return 'BadUrl: ' + url;
		case 1:
			return 'Timeout';
		case 2:
			return 'NetworkError';
		case 3:
			var resp = err.a;
			return 'BadStatus ' + resp.iD.id;
		default:
			var info = err.a;
			var resp = err.b;
			return 'BadPayload: ' + (info + ('; ' + resp.iD.id));
	}
};
var $author$project$Marketing$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var section = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bW: section}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cS: A2($elm$core$List$cons, error, model.cS)
						}),
					$author$project$Shared$Utils$Console$error(error));
			default:
				var result = msg.a;
				if (!result.$) {
					var jobsResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cX: $elm$core$Maybe$Just(jobsResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$Shared$Utils$Console$error(
							'Error from hire.withgoogle.com , ' + $author$project$Shared$Utils$Http$showError(errMsg)));
				}
		}
	});
var $author$project$Marketing$Thesis$Fail = 1;
var $author$project$Marketing$Thesis$SubmittedEmail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Marketing$Thesis$Success = 0;
var $author$project$Marketing$Thesis$Transcript = 1;
var $elm$http$Http$Internal$StringBody = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		$elm$http$Http$Internal$StringBody,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$http$Http$post = F3(
	function (url, body, decoder) {
		return $elm$http$Http$request(
			{
				hr: body,
				fY: $elm$http$Http$expectJson(decoder),
				f4: _List_Nil,
				ie: 'POST',
				g5: $elm$core$Maybe$Nothing,
				iS: url,
				iW: false
			});
	});
var $author$project$Marketing$Thesis$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cS: A2($elm$core$List$cons, error, model.cS)
						}),
					$author$project$Shared$Utils$Console$error(error));
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b0: 0}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b0: 1}),
					$elm$core$Platform$Cmd$none);
			case 5:
				if ((msg.a.$ === 1) && (msg.a.a.$ === 3)) {
					var r = msg.a.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aV: 1}),
						$author$project$Shared$Utils$Console$error(r.iD.id));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aV: 0}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var _v1 = model.b5;
				if (_v1 === '') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aV: 1}),
						$elm$core$Platform$Cmd$none);
				} else {
					var submitEmailCmd = A2(
						$elm$http$Http$send,
						A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$Marketing$Thesis$SubmittedEmail),
						A3(
							$elm$http$Http$post,
							'https://compoundtreasury.com/inbound-crm',
							$elm$http$Http$jsonBody(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'email',
											$elm$json$Json$Encode$string(model.b5))
										]))),
							$elm$json$Json$Decode$succeed(true)));
					return _Utils_Tuple2(model, submitEmailCmd);
				}
			case 6:
				var userEmail = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b5: userEmail, aV: 2}),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var episodeDataResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dc: $elm$core$Maybe$Just(episodeDataResponse)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errMsg = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$Shared$Utils$Console$error(
							'Error from sheets api , ' + $author$project$Shared$Utils$Http$showError(errMsg)));
				}
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var location = msg.a;
				return A2($author$project$Main$transitionPage, model, location);
			case 1:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dz: $elm$core$Maybe$Just(time)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var timeZone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dA: timeZone}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var page_ = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Main$maybeScroll(page_));
			case 4:
				var newMobileMenuShowing = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c0: newMobileMenuShowing}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var marketingHomeMsg = msg.a;
				var _v1 = A2($author$project$Marketing$Home$update, marketingHomeMsg, model.ch);
				var updatedMarketingHomeModel = _v1.a;
				var marketingHomeCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ch: updatedMarketingHomeModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$marketingHomeTranslator, marketingHomeCmd));
			case 6:
				var marketingThesisMsg = msg.a;
				var _v2 = A2($author$project$Marketing$Thesis$update, marketingThesisMsg, model.aI);
				var updatedMarketingThesisModel = _v2.a;
				var marketingThesisCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: updatedMarketingThesisModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$marketingThesisTranslator, marketingThesisCmd));
			case 7:
				var copyText = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Main$copy(copyText));
			default:
				var error = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cS: model.cS}),
					$author$project$Shared$Utils$Console$log(error));
		}
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Shared$Utils$CompoundHtmlAttributes$id = function (idName) {
	return A2($elm$html$Html$Attributes$attribute, 'id', idName);
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Main$MarketingCommonParentMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Marketing$CommonViews$translator = F2(
	function (_v0, msg) {
		var onParentMsg = _v0.ik;
		var parentMsg = msg;
		return onParentMsg(parentMsg);
	});
var $author$project$Main$marketingCommonTranslator = $author$project$Marketing$CommonViews$translator(
	{ik: $author$project$Main$MarketingCommonParentMsg});
var $author$project$Shared$Utils$CompoundHtmlAttributes$External = 2;
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Shared$Utils$CompoundHtmlAttributes$class = function (className) {
	return A2($elm$html$Html$Attributes$attribute, 'class', className);
};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $author$project$Strings$Translations$get_in_touch = function (lang) {
	if (!lang) {
		return 'Get in touch';
	} else {
		return 'Ponerse en contacto';
	}
};
var $author$project$Shared$Utils$CompoundHtmlAttributes$href = F2(
	function (linkType, linkString) {
		var relAttributes = function () {
			switch (linkType) {
				case 1:
					return _List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'rel', 'internal')
						]);
				case 2:
					return _List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'rel', 'external')
						]);
				default:
					return _List_Nil;
			}
		}();
		return _Utils_ap(
			relAttributes,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'href', linkString)
				]));
	});
var $author$project$Strings$Translations$linkedin = function (lang) {
	if (!lang) {
		return 'LinkedIn';
	} else {
		return 'LinkedIn';
	}
};
var $author$project$Strings$Translations$medium = function (lang) {
	if (!lang) {
		return 'Medium';
	} else {
		return 'Medio';
	}
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Strings$Translations$substack = function (lang) {
	if (!lang) {
		return 'Substack';
	} else {
		return 'subpila';
	}
};
var $author$project$Shared$Utils$CompoundHtmlAttributes$target = function (targetName) {
	return A2($elm$html$Html$Attributes$attribute, 'target', targetName);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Strings$Translations$twitter = function (lang) {
	if (!lang) {
		return 'Twitter';
	} else {
		return 'Gorjeo';
	}
};
var $author$project$Marketing$CommonViews$pageFooter = function (userLanguage) {
	return A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer'),
				$author$project$Shared$Utils$CompoundHtmlAttributes$id('footer')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section__text')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$get_in_touch(userLanguage))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section__socials')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://twitter.com/compoundfinance')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$twitter(userLanguage))
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$medium(userLanguage))
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.substack.com/')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$substack(userLanguage))
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.linkedin.com/company/compound-labs/')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$linkedin(userLanguage))
									]))
							]))
					]))
			]));
};
var $author$project$Marketing$CommonViews$ForParent = $elm$core$Basics$identity;
var $author$project$Shared$Utils$CompoundHtmlAttributes$PageNavigation = 0;
var $author$project$Marketing$CommonViews$ToggleMobileNavMsg = $elm$core$Basics$identity;
var $author$project$Page$getHrefUrl = function (page) {
	if (!page.$) {
		return '/';
	} else {
		var episode = page.a;
		var subsection = function () {
			if (!episode.$) {
				var s = episode.a;
				return '#' + s;
			} else {
				return '';
			}
		}();
		var base = '/thesis';
		return _Utils_ap(base, subsection);
	}
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$core$Basics$not = _Basics_not;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Strings$Translations$overview = function (lang) {
	if (!lang) {
		return 'Overview';
	} else {
		return 'Descripción general';
	}
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Marketing$CommonViews$pageHeader = F3(
	function (userLanguage, page, shouldShowMobileMenu) {
		var mobileHeaderClass = function () {
			var headerClass_ = '';
			return shouldShowMobileMenu ? ('active' + headerClass_) : ('' + headerClass_);
		}();
		var headerClass = ' header--marketing';
		var _v0 = function () {
			var emptyClasses = {g3: '', fn: ''};
			if (page.$ === 1) {
				return _Utils_update(
					emptyClasses,
					{fn: ' active'});
			} else {
				return emptyClasses;
			}
		}();
		var teamClass = _v0.g3;
		var thesisClass = _v0.fn;
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$class('header' + headerClass),
					$author$project$Shared$Utils$CompoundHtmlAttributes$id('header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('container-large')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('row align-middle')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('col-xs-3')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$append,
												A2(
													$author$project$Shared$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$Page$getHrefUrl($author$project$Page$Home)),
												_List_fromArray(
													[
														$author$project$Shared$Utils$CompoundHtmlAttributes$class('header__brand'),
														$elm$html$Html$Events$onClick(false)
													])),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('mobile-hide col-xs-6 text-center header__links')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$author$project$Shared$Utils$CompoundHtmlAttributes$class(thesisClass),
												A2(
													$author$project$Shared$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$Page$getHrefUrl(
														$author$project$Page$Thesis($elm$core$Maybe$Nothing)))),
											_List_fromArray(
												[
													$elm$html$Html$text('Thesis')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('col-xs-9 col-sm-3 text-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('mobile-only hamburger ' + mobileHeaderClass),
													$elm$html$Html$Events$onClick(!shouldShowMobileMenu)
												]),
											_List_fromArray(
												[
													A2($elm$html$Html$span, _List_Nil, _List_Nil),
													A2($elm$html$Html$span, _List_Nil, _List_Nil),
													A2($elm$html$Html$span, _List_Nil, _List_Nil)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('mobile-header ' + mobileHeaderClass)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('links')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(false),
												A2(
													$author$project$Shared$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$Page$getHrefUrl($author$project$Page$Home))),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$overview(userLanguage))
																]))
														]))
												])),
											A2(
											$elm$html$Html$a,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Events$onClick(false),
												A2(
													$author$project$Shared$Utils$CompoundHtmlAttributes$href,
													0,
													$author$project$Page$getHrefUrl(
														$author$project$Page$Thesis($elm$core$Maybe$Nothing)))),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Thesis')
																]))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Strings$Translations$a16z = function (lang) {
	if (!lang) {
		return 'a16z';
	} else {
		return 'a16z';
	}
};
var $author$project$Strings$Translations$and = function (lang) {
	if (!lang) {
		return 'and ';
	} else {
		return 'y';
	}
};
var $author$project$Strings$Translations$and_yes = function (lang) {
	if (!lang) {
		return 'And yes,';
	} else {
		return 'Y si,';
	}
};
var $author$project$Strings$Translations$april_2023 = function (lang) {
	if (!lang) {
		return 'April 2023';
	} else {
		return 'marzo 2022';
	}
};
var $author$project$Strings$Translations$aug_2022 = function (lang) {
	if (!lang) {
		return 'August 2022';
	} else {
		return '16 de agosto de 2022';
	}
};
var $author$project$Strings$Translations$bain_capital = function (lang) {
	if (!lang) {
		return 'Bain Capital';
	} else {
		return 'Capital de Baín';
	}
};
var $author$project$Strings$Translations$blockworks = function (lang) {
	if (!lang) {
		return 'Blockworks';
	} else {
		return 'Bloques';
	}
};
var $author$project$Strings$Translations$blog_posts = function (lang) {
	if (!lang) {
		return 'Blog Posts';
	} else {
		return 'Publicaciones de blog';
	}
};
var $author$project$Strings$Translations$coinbase_ventures = function (lang) {
	if (!lang) {
		return 'Coinbase Ventures';
	} else {
		return 'Coinbase Ventures';
	}
};
var $author$project$Strings$Translations$coindesk = function (lang) {
	if (!lang) {
		return 'Coindesk';
	} else {
		return 'Coindesk';
	}
};
var $elm$svg$Svg$Attributes$clipRule = _VirtualDom_attribute('clip-rule');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Marketing$Logos$compoundLogo = function (className) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class(className),
				$elm$svg$Svg$Attributes$viewBox('0 0 553 73'),
				$elm$svg$Svg$Attributes$fill('none')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M364.928 5.14882V21.6593C362.927 18.9874 358.912 16.6151 353.434 16.6271C342.156 16.6271 335.552 25.6731 335.552 36.9477C335.552 48.2223 342.156 57.2683 353.434 57.2683C358.924 57.2683 363.31 55.1835 365.755 51.625V56.3697H375.476V0.5L366.714 2.83639C365.66 3.11197 364.928 4.0585 364.928 5.14882ZM355.64 25.8888C361.501 25.8888 365.216 30.6334 365.216 36.9357C365.216 43.25 361.513 47.9946 355.64 47.9826C349.779 47.9826 346.063 43.238 346.063 36.9357C346.063 30.6334 349.779 25.8888 355.64 25.8888Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M305.132 22.2464V17.5017H295.424V56.3697H305.959V34.1201C305.959 28.9201 308.776 25.6611 313.893 25.6611C318.568 25.6611 320.498 28.7763 320.498 33.3053V56.3697H331.033V32.1072C331.033 22.8335 326.059 16.6031 316.71 16.6031C310.921 16.6031 306.918 18.9036 305.132 22.2464Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M271.871 57.2683C260.521 57.2683 254.204 52.3679 254.204 39.7633V17.5017H264.74V39.9071C264.74 45.3228 267.185 47.8389 271.859 47.8389C276.534 47.8389 278.979 45.3108 278.979 39.9071V17.5017H289.514V39.7514C289.538 52.3679 283.222 57.2683 271.871 57.2683Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M209.246 36.9477C209.246 47.9946 217.192 57.2683 229.657 57.2683C242.122 57.2683 250.057 47.9946 250.069 36.9477C250.069 25.9008 242.122 16.6271 229.657 16.6271C217.18 16.6271 209.246 25.9008 209.246 36.9477ZM229.645 47.9826C224.084 47.9826 219.781 43.9089 219.781 36.9357C219.781 29.9625 224.096 25.8888 229.645 25.8888C235.207 25.8888 239.51 29.9745 239.51 36.9357C239.51 43.897 235.207 47.9826 229.645 47.9826Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M176.358 52.2241V68.3871C176.358 69.4774 175.627 70.424 174.572 70.6995L165.822 73V17.5017H175.543V22.2464C177.988 18.6879 182.374 16.6031 187.864 16.6031C199.142 16.6031 205.747 25.6491 205.747 36.9237C205.747 48.1983 199.142 57.2443 187.864 57.2443C182.362 57.2683 178.359 54.896 176.358 52.2241ZM176.058 36.9357C176.058 43.238 179.774 47.9826 185.635 47.9826C191.496 47.9826 195.211 43.238 195.211 36.9357C195.211 30.6334 191.496 25.8888 185.635 25.8888C179.774 25.8888 176.058 30.6334 176.058 36.9357Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M135.606 56.3697V34.2638C135.606 28.8482 138.195 25.6611 143.169 25.6611C147.843 25.6611 149.401 29.004 149.401 33.3053V56.3697H159.937V32.1072C159.937 23.0611 155.19 16.6031 145.842 16.6031C140.28 16.6031 136.349 18.3045 132.933 22.0906C130.704 18.6759 126.928 16.6031 121.654 16.6031C116.093 16.6031 112.234 18.9036 110.448 22.2464V17.5017H100.727V56.3817H111.263V34.0482C111.263 28.8482 113.936 25.6611 118.838 25.6611C123.512 25.6611 125.07 29.004 125.07 33.3053V56.3697H135.606Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M75.0783 57.2683C62.6132 57.2683 54.6667 47.9946 54.6667 36.9477C54.6667 25.9008 62.6012 16.6271 75.0783 16.6271C87.5554 16.6271 95.4899 25.9008 95.4899 36.9477C95.4899 47.9946 87.5434 57.2683 75.0783 57.2683ZM65.2141 36.9357C65.2141 43.9089 69.5169 47.9826 75.0783 47.9826C80.6396 47.9826 84.9425 43.897 84.9425 36.9357C84.9425 29.9745 80.6396 25.8888 75.0783 25.8888C69.5169 25.8888 65.2141 29.9625 65.2141 36.9357Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M26.4883 2.58478C11.3504 2.58478 0 13.4999 0 29.9625C0 46.437 11.3504 57.3402 26.4883 57.3162C39.6246 57.3162 50.0162 49.5283 52.0178 36.6961H40.4397C38.8815 43.2979 33.5359 47.0121 26.4883 47.0121C16.8399 47.0121 11.4223 40.3385 11.4223 29.9505C11.4223 19.5626 16.8399 12.8889 26.4883 12.8889C33.5359 12.8889 38.8815 16.6031 40.4397 23.2049H52.0178C50.0162 10.3727 39.6246 2.58478 26.4883 2.58478Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M429.54 56.4688V46.5521H406.221V0.691762L397.318 2.76313C396.202 3.02277 395.412 4.01704 395.412 5.16242V56.4688H429.54Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M471.401 17.69H461.702V22.4264C459.259 18.8741 454.892 16.8019 449.413 16.8019C438.16 16.8019 431.572 25.8306 431.572 37.0794C431.572 48.3282 438.16 57.3569 449.413 57.3569C454.892 57.3569 459.259 55.2847 461.702 51.7325V56.4688H471.401V17.69ZM451.634 26.0526C457.483 26.0526 461.184 30.789 461.184 37.0794C461.184 43.3699 457.483 48.1062 451.634 48.1062C445.786 48.1062 442.084 43.3699 442.084 37.0794C442.084 30.789 445.786 26.0526 451.634 26.0526Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fillRule('evenodd'),
						$elm$svg$Svg$Attributes$clipRule('evenodd'),
						$elm$svg$Svg$Attributes$d('M488.177 21.8343C490.176 19.1701 494.174 16.8019 499.652 16.8019C510.905 16.8019 517.494 25.8306 517.494 37.0794C517.494 48.3282 510.905 57.3569 499.652 57.3569C494.174 57.3569 489.806 55.2847 487.363 51.7325V56.4688H477.665V5.7675C477.665 4.46209 478.545 3.32065 479.808 2.98825L488.177 0.785164V21.8343ZM487.881 37.0794C487.881 43.3699 491.583 48.1062 497.431 48.1062C503.28 48.1062 506.981 43.3699 506.981 37.0794C506.981 30.789 503.28 26.0526 497.431 26.0526C491.583 26.0526 487.881 30.789 487.881 37.0794Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M520.27 47.1283C521.736 52.8986 526.284 57.3569 536.566 57.3569C547.078 57.3569 553.001 51.8805 553.001 44.924C553.001 35.2207 545.016 33.8569 538.786 32.7927C534.8 32.1119 531.532 31.5537 531.532 29.0128C531.532 26.8667 533.456 25.2386 536.862 25.2386C540.485 25.2386 542.336 27.2829 542.675 29.6061C542.675 29.6061 551.248 27.3523 552.394 27.0104C551.046 21.9812 546.659 16.8019 536.936 16.8019C527.386 16.8019 521.389 22.5744 521.389 29.6049C521.389 38.9315 529.298 40.3522 535.532 41.472C539.586 42.2004 542.932 42.8014 542.932 45.368C542.932 47.5142 540.86 48.9203 536.862 48.9203C532.749 48.9203 530.803 46.7554 530.465 44.3404C530.465 44.3404 521.626 46.6955 520.27 47.1283Z'),
						$elm$svg$Svg$Attributes$fill('#F5E7D7')
					]),
				_List_Nil)
			]));
};
var $author$project$Strings$Translations$compound_labs_description = function (lang) {
	if (!lang) {
		return 'is a technology company building infrastructure for the future of finance. We bring paradigm-shifting technologies to market—emphasizing security, capital efficiency, and radical transparency.';
	} else {
		return 'es una empresa de tecnología que crea infraestructura para el futuro de las finanzas.';
	}
};
var $author$project$Strings$Translations$compound_treasury = function (lang) {
	if (!lang) {
		return 'Compound Treasury';
	} else {
		return 'Tesoro compuesto';
	}
};
var $author$project$Strings$Translations$created_first = function (lang) {
	if (!lang) {
		return 'We developed the first ';
	} else {
		return 'Desarrollamos el original';
	}
};
var $author$project$Strings$Translations$decrypt = function (lang) {
	if (!lang) {
		return 'Decrypt';
	} else {
		return 'descifrar';
	}
};
var $author$project$Strings$Translations$defi_summer_return = function (lang) {
	if (!lang) {
		return 'DeFi Summer Is Over. Will It Ever Return?';
	} else {
		return 'El brazo empresarial de Compound recibe la calificación crediticia de S&P en DeFi First';
	}
};
var $author$project$Strings$Translations$deploy_on_arbitrum = function (lang) {
	if (!lang) {
		return 'Crypto Lender Compound Deploys on Ethereum Layer-2 Arbitrum';
	} else {
		return 'El fundador de Compound no está preocupado por los valores predeterminados de DeFi';
	}
};
var $author$project$Strings$Translations$dragonfly_capital = function (lang) {
	if (!lang) {
		return 'Dragonfly Capital';
	} else {
		return 'Capital de la libélula';
	}
};
var $author$project$Strings$Translations$emcumber_blog = function (lang) {
	if (!lang) {
		return 'Encumber: Enabling Non-custodial DeFi';
	} else {
		return 'Grabar: habilitar DeFi sin custodia';
	}
};
var $author$project$Strings$Translations$for_defi = function (lang) {
	if (!lang) {
		return ' for decentralized finance';
	} else {
		return 'para finanzas descentralizadas';
	}
};
var $author$project$Strings$Translations$gateway = function (lang) {
	if (!lang) {
		return 'Gateway';
	} else {
		return 'puerta';
	}
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Strings$Translations$institutional_products = function (lang) {
	if (!lang) {
		return 'and institutional products such as ';
	} else {
		return 'y productos institucionales como';
	}
};
var $author$project$Strings$Translations$jan_2023 = function (lang) {
	if (!lang) {
		return 'January 2023';
	} else {
		return '23 enero 2023';
	}
};
var $author$project$Strings$Translations$june_2023 = function (lang) {
	if (!lang) {
		return 'June 2023';
	} else {
		return '27 junio 2023';
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Strings$Translations$liquidity_protocol = function (lang) {
	if (!lang) {
		return 'algorithmic money markets';
	} else {
		return 'protocolo de liquidez';
	}
};
var $author$project$Strings$Translations$may_2023 = function (lang) {
	if (!lang) {
		return ' May 2023';
	} else {
		return '17 mayo 2023';
	}
};
var $author$project$Strings$Translations$multi_chain_comet = function (lang) {
	if (!lang) {
		return 'DeFi protocol Compound says multi-chain Comet upgrade is ready to go';
	} else {
		return 'FIS se asocia con Fireblocks para llevar criptocomercio, DeFi y servicios de staking a su base global de clientes';
	}
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Marketing$Home$ActiveSectionChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Shared$Utils$CompoundHtmlAttributes$Internal = 1;
var $author$project$Marketing$Home$navBar = F2(
	function (currentSection, model) {
		var navClass = function (section) {
			return _Utils_eq(model.bW, section) ? ' active' : '';
		};
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class(
						'nav-item ' + (currentSection + navClass(currentSection))),
						$elm$html$Html$Events$onClick(
						$author$project$Marketing$Home$ActiveSectionChanged(currentSection))
					]),
				A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 1, currentSection)),
			_List_fromArray(
				[
					A2($elm$html$Html$span, _List_Nil, _List_Nil)
				]));
	});
var $author$project$Strings$Translations$option_for_genesis = function (lang) {
	if (!lang) {
		return 'Compound Labs Founder on Options for Genesis Creditors';
	} else {
		return 'A pesar de los problemas de DeFi, Siam Commercial Bank pone fondos';
	}
};
var $author$project$Strings$Translations$our_team_experience = function (lang) {
	if (!lang) {
		return 'Our team is comprised of tech industry veterans who’ve built global-scale products at companies like Coinbase, Google, Meta, Plaid, and Square.';
	} else {
		return 'Nuestro equipo está compuesto por veteranos de la industria tecnológica que han creado productos a escala de Internet en empresas como Coinbase, Google, Meta, Plaid y Square.';
	}
};
var $author$project$Strings$Translations$blog = function (lang) {
	if (!lang) {
		return 'Blog';
	} else {
		return 'Pantano';
	}
};
var $author$project$Strings$Translations$career = function (lang) {
	if (!lang) {
		return 'Careers';
	} else {
		return 'Carreras';
	}
};
var $author$project$Strings$Translations$copyright = function (lang) {
	if (!lang) {
		return '© 2023 Compound Labs, Inc.';
	} else {
		return '© 2022 Compound Labs, Inc.';
	}
};
var $author$project$Marketing$Home$pageFooter = function (userLanguage) {
	return A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer'),
				$author$project$Shared$Utils$CompoundHtmlAttributes$id('footer')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section__text')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$get_in_touch(userLanguage))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__section__socials')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://twitter.com/compoundfinance')),
								_List_fromArray(
									[
										$elm$html$Html$text('Twitter')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance')),
								_List_fromArray(
									[
										$elm$html$Html$text('Medium')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.substack.com/')),
								_List_fromArray(
									[
										$elm$html$Html$text('Substack')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('/')
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.linkedin.com/company/compound-labs/')),
								_List_fromArray(
									[
										$elm$html$Html$text('LinkedIn')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__legal-content')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Strings$Translations$copyright(userLanguage))
							])),
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://jobs.lever.co/compound-2')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$career(userLanguage))
									])),
								A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank'),
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('footer__legal-content__blog')
										]),
									A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://blog.compoundlabs.xyz/encumber/')),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Strings$Translations$blog(userLanguage))
									]))
							]))
					]))
			]));
};
var $author$project$Strings$Translations$paradigm = function (lang) {
	if (!lang) {
		return 'Paradigm';
	} else {
		return 'Paradigma';
	}
};
var $author$project$Strings$Translations$pioneered_opensource = function (lang) {
	if (!lang) {
		return ' and pioneered an open-source system for ';
	} else {
		return 'para Ethereum y lanzó el primer código abierto';
	}
};
var $author$project$Strings$Translations$polychain_capital = function (lang) {
	if (!lang) {
		return 'Polychain Capital';
	} else {
		return 'Capital de policadena';
	}
};
var $author$project$Strings$Translations$protocol_governance = function (lang) {
	if (!lang) {
		return 'protocol governance';
	} else {
		return 'sistema de gobierno';
	}
};
var $author$project$Strings$Translations$recent_press = function (lang) {
	if (!lang) {
		return 'Recent Press';
	} else {
		return 'Prensa reciente';
	}
};
var $author$project$Strings$Translations$reputation = function (lang) {
	if (!lang) {
		return 'We share our thinking on our blog, and top industry publications write about us regularly:';
	} else {
		return 'Las principales publicaciones de negocios, finanzas y tecnología escriben regularmente sobre nuestro trabajo (y, por cierto, nosotros también).';
	}
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Strings$Translations$staff_engineer = function (lang) {
	if (!lang) {
		return 'Staff Engineer';
	} else {
		return 'Ingeniero de equipo';
	}
};
var $author$project$Strings$Translations$adam_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/adam-bavosa/';
	} else {
		return 'https://www.linkedin.com/in/adam-bavosa/';
	}
};
var $author$project$Strings$Translations$adam_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/adambavosa';
	} else {
		return 'https://twitter.com/adambavosa';
	}
};
var $author$project$Strings$Translations$alex_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/alexandfox/';
	} else {
		return 'https://www.linkedin.com/in/alexandfox/';
	}
};
var $author$project$Strings$Translations$alex_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/alalafox';
	} else {
		return 'https://twitter.com/alalafox';
	}
};
var $author$project$Strings$Translations$aryan_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/aryanbhasin/';
	} else {
		return 'https://www.linkedin.com/in/aryanbhasin/';
	}
};
var $author$project$Strings$Translations$banky_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/bankoleadebajo/';
	} else {
		return 'https://www.linkedin.com/in/bankoleadebajo/';
	}
};
var $author$project$Strings$Translations$banky_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/0xbanky';
	} else {
		return 'https://twitter.com/0xbanky';
	}
};
var $author$project$Strings$Translations$ceo = function (lang) {
	if (!lang) {
		return 'CEO';
	} else {
		return 'CEO';
	}
};
var $author$project$Strings$Translations$coburn_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/coburndberry/';
	} else {
		return 'https://www.linkedin.com/in/coburndberry/';
	}
};
var $author$project$Strings$Translations$coburn_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/crododile';
	} else {
		return 'https://twitter.com/crododile';
	}
};
var $author$project$Strings$Translations$cto = function (lang) {
	if (!lang) {
		return 'CTO';
	} else {
		return 'CTO';
	}
};
var $author$project$Strings$Translations$design_lead = function (lang) {
	if (!lang) {
		return 'Design Lead';
	} else {
		return 'Diseñador sénior de productos';
	}
};
var $author$project$Strings$Translations$developer_relations_lead = function (lang) {
	if (!lang) {
		return 'Developer Relations Lead';
	} else {
		return 'Responsable de relaciones con desarrolladores';
	}
};
var $author$project$Strings$Translations$director_of_engineering = function (lang) {
	if (!lang) {
		return 'Director of Engineering';
	} else {
		return 'Jefe de Ingeniería Front End';
	}
};
var $author$project$Strings$Translations$director_of_marketing = function (lang) {
	if (!lang) {
		return 'Director of Marketing';
	} else {
		return 'Director de Márketing';
	}
};
var $author$project$Strings$Translations$elliot_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/elliotttran/';
	} else {
		return 'https://www.linkedin.com/in/elliotttran/';
	}
};
var $author$project$Strings$Translations$elliot_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/elliotttran';
	} else {
		return 'https://twitter.com/elliotttran';
	}
};
var $author$project$Strings$Translations$engineer = function (lang) {
	if (!lang) {
		return 'Engineer';
	} else {
		return 'Ingeniero';
	}
};
var $author$project$Strings$Translations$geoff_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/geoffrey-hayes-a0b0952a/';
	} else {
		return 'https://www.linkedin.com/en/geoffrey-hayes-a0b0952a/';
	}
};
var $author$project$Strings$Translations$geoff_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/justHGH';
	} else {
		return 'https://twitter.com/justHGH';
	}
};
var $author$project$Strings$Translations$hans_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/hanswang1992/';
	} else {
		return 'https://www.linkedin.com/in/hanswang1992/';
	}
};
var $author$project$Strings$Translations$hans_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/Hans_Wang_22';
	} else {
		return 'https://twitter.com/Hans_Wang_22';
	}
};
var $author$project$Strings$Translations$head_of_design = function (lang) {
	if (!lang) {
		return 'Head of Design';
	} else {
		return 'Jefe de Diseño';
	}
};
var $author$project$Strings$Translations$head_of_protocol = function (lang) {
	if (!lang) {
		return 'Head of Protocol';
	} else {
		return 'Jefe de Protocolo';
	}
};
var $author$project$Strings$Translations$jayson_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/jaysonhobby/';
	} else {
		return 'https://www.linkedin.com/in/jaysonhobby/';
	}
};
var $author$project$Strings$Translations$jayson_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/jaysonhobby';
	} else {
		return 'https://twitter.com/jaysonhobby';
	}
};
var $author$project$Strings$Translations$jordan_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/fluffywaffles/';
	} else {
		return 'https://www.linkedin.com/in/fluffywaffles/';
	}
};
var $author$project$Strings$Translations$joseph_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/josephlih/';
	} else {
		return 'https://www.linkedin.com/in/josephlih/';
	}
};
var $author$project$Strings$Translations$josiah_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/josiahaustingulden/';
	} else {
		return 'https://www.linkedin.com/en/josiahaustingulden/';
	}
};
var $author$project$Strings$Translations$josiah_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/jgulden';
	} else {
		return 'https://twitter.com/jgulden';
	}
};
var $author$project$Strings$Translations$kevin_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/kevincheng96/';
	} else {
		return 'https://www.linkedin.com/in/kevincheng96/';
	}
};
var $author$project$Strings$Translations$kevin_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/kevincheng96';
	} else {
		return 'https://twitter.com/kevincheng96';
	}
};
var $author$project$Strings$Translations$mykel_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/mykel-pereira-9b173a7b/';
	} else {
		return 'https://www.linkedin.com/in/mykel-pereira-9b173a7b/';
	}
};
var $author$project$Strings$Translations$mykel_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/mykelp';
	} else {
		return 'https://twitter.com/mykelp';
	}
};
var $author$project$Strings$Translations$scott_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/scott-silver-3b747b86/';
	} else {
		return 'https://www.linkedin.com/en/scott-silver-3b747b86/';
	}
};
var $author$project$Strings$Translations$scott_twitter = function (lang) {
	if (!lang) {
		return 'https://twitter.com/Scott_Silver_';
	} else {
		return 'https://twitter.com/Scott_Silver_';
	}
};
var $author$project$Strings$Translations$senior_engineer = function (lang) {
	if (!lang) {
		return 'Senior Engineer';
	} else {
		return 'Ingeniero senior';
	}
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Shared$Utils$CompoundHtmlAttributes$src = function (srcName) {
	return A2($elm$html$Html$Attributes$attribute, 'src', srcName);
};
var $author$project$Marketing$Home$teamMember = F5(
	function (imgSrc, name, title, linkedIn, twitter) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$class('col-sm-6 member')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('member__img'),
							$author$project$Shared$Utils$CompoundHtmlAttributes$src(imgSrc)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$h4,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class(' L4 heading heading--emphasized member__name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(name)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('member__title L1 meta')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							($elm$core$String$length(twitter) > 0) ? $author$project$Shared$Utils$CompoundHtmlAttributes$class('member__bio member__bio--margin') : $author$project$Shared$Utils$CompoundHtmlAttributes$class('member__bio')
						]),
					_List_fromArray(
						[
							($elm$core$String$length(twitter) > 0) ? A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('icn icn--twitter'),
										$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
									]),
								A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, twitter)),
							_List_Nil) : A2($elm$html$Html$span, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('icn icn--linkedin'),
										$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
									]),
								A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, linkedIn)),
							_List_Nil)
						]))
				]));
	});
var $author$project$Strings$Translations$torrey_linkedin = function (lang) {
	if (!lang) {
		return 'https://www.linkedin.com/in/torreyatcitty/';
	} else {
		return 'https://www.linkedin.com/in/torreyatcitty/';
	}
};
var $author$project$Strings$Translations$vp_of_product = function (lang) {
	if (!lang) {
		return 'VP of Product';
	} else {
		return 'Vicepresidente de Ingeniería';
	}
};
var $author$project$Marketing$Home$teamMembers = function (userLanguage) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('members')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/jayson.webp',
				'Jayson Hobby',
				$author$project$Strings$Translations$ceo(userLanguage),
				$author$project$Strings$Translations$jayson_linkedin(userLanguage),
				$author$project$Strings$Translations$jayson_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/geoff.webp',
				'Geoffrey Hayes',
				$author$project$Strings$Translations$cto(userLanguage),
				$author$project$Strings$Translations$geoff_linkedin(userLanguage),
				$author$project$Strings$Translations$geoff_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/torrey.webp',
				'Torrey Atcitty',
				$author$project$Strings$Translations$director_of_engineering(userLanguage),
				$author$project$Strings$Translations$torrey_linkedin(userLanguage),
				''),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/coburn.webp',
				'Coburn Berry',
				$author$project$Strings$Translations$staff_engineer(userLanguage),
				$author$project$Strings$Translations$coburn_linkedin(userLanguage),
				$author$project$Strings$Translations$coburn_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/mykel.webp',
				'Mykel Pereira',
				$author$project$Strings$Translations$vp_of_product(userLanguage),
				$author$project$Strings$Translations$mykel_linkedin(userLanguage),
				$author$project$Strings$Translations$mykel_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/adam.webp',
				'Adam Bavosa',
				$author$project$Strings$Translations$developer_relations_lead(userLanguage),
				$author$project$Strings$Translations$adam_linkedin(userLanguage),
				$author$project$Strings$Translations$adam_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/kevin.webp',
				'Kevin Cheng',
				$author$project$Strings$Translations$head_of_protocol(userLanguage),
				$author$project$Strings$Translations$kevin_linkedin(userLanguage),
				$author$project$Strings$Translations$kevin_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/hans.webp',
				'Hans Wang',
				$author$project$Strings$Translations$senior_engineer(userLanguage),
				$author$project$Strings$Translations$hans_linkedin(userLanguage),
				$author$project$Strings$Translations$hans_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/scott.webp',
				'Scott Silver',
				$author$project$Strings$Translations$senior_engineer(userLanguage),
				$author$project$Strings$Translations$scott_linkedin(userLanguage),
				$author$project$Strings$Translations$scott_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/joseph.webp',
				'Joseph Lih',
				$author$project$Strings$Translations$director_of_marketing(userLanguage),
				$author$project$Strings$Translations$joseph_linkedin(userLanguage),
				''),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/josiah.webp',
				'Josiah Gulden',
				$author$project$Strings$Translations$head_of_design(userLanguage),
				$author$project$Strings$Translations$josiah_linkedin(userLanguage),
				$author$project$Strings$Translations$josiah_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/jordan.webp',
				'Jordan Timmerman',
				$author$project$Strings$Translations$senior_engineer(userLanguage),
				$author$project$Strings$Translations$jordan_linkedin(userLanguage),
				''),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/aryan.webp',
				'Aryan Bhasin',
				$author$project$Strings$Translations$engineer(userLanguage),
				$author$project$Strings$Translations$aryan_linkedin(userLanguage),
				''),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/elliot.webp',
				'Elliott Tran',
				$author$project$Strings$Translations$design_lead(userLanguage),
				$author$project$Strings$Translations$elliot_linkedin(userLanguage),
				$author$project$Strings$Translations$elliot_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/alex.webp',
				'Alex Fox',
				$author$project$Strings$Translations$senior_engineer(userLanguage),
				$author$project$Strings$Translations$alex_linkedin(userLanguage),
				$author$project$Strings$Translations$alex_twitter(userLanguage)),
				A5(
				$author$project$Marketing$Home$teamMember,
				'images/team/banky.webp',
				'Banky Adebajo',
				$author$project$Strings$Translations$senior_engineer(userLanguage),
				$author$project$Strings$Translations$banky_linkedin(userLanguage),
				$author$project$Strings$Translations$banky_twitter(userLanguage))
			]));
};
var $author$project$Strings$Translations$the_block = function (lang) {
	if (!lang) {
		return 'The Block';
	} else {
		return 'La información';
	}
};
var $author$project$Strings$Translations$we_are_backed_by = function (lang) {
	if (!lang) {
		return 'We\'re backed by world-class investment firms: ';
	} else {
		return 'Estamos respaldados por las mejores firmas de inversión enfocadas en criptografía del mundo:';
	}
};
var $author$project$Strings$Translations$we_are_hiring = function (lang) {
	if (!lang) {
		return 'we\'re hiring.';
	} else {
		return 'estamos contratando.';
	}
};
var $author$project$Strings$Translations$we_work_on_cross_chain = function (lang) {
	if (!lang) {
		return 'We work cross-chain, and have ideated on Layer 1 blockchains with ';
	} else {
		return 'Trabajamos en cadenas cruzadas y hemos ideado cadenas de bloques de capa 1 con';
	}
};
var $author$project$Marketing$Home$view = F3(
	function (userLanguage, isSafari, model) {
		var safariClass = isSafari ? ' safari' : '';
		var navClass = function (section) {
			return _Utils_eq(model.bW, section) ? ' active' : '';
		};
		var jobs = function () {
			var _v0 = model.cX;
			if (!_v0.$) {
				var responseJobs = _v0.a;
				return responseJobs;
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$id('marketing-home')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-holder')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-background')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-lines')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-lines grid-lines--second' + safariClass)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-lines grid-lines--third' + safariClass)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-mask-opacity')
									]),
								_List_Nil)
							]),
						isSafari ? _List_Nil : _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-mask-hard-light')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('grid-mask-hue')
									]),
								_List_Nil)
							]))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('pagination')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$nav,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$Marketing$Home$navBar, '#intro', model),
									A2($author$project$Marketing$Home$navBar, '#about', model),
									A2($author$project$Marketing$Home$navBar, '#investors', model),
									A2($author$project$Marketing$Home$navBar, '#press', model),
									A2($author$project$Marketing$Home$navBar, '#team', model),
									A2($author$project$Marketing$Home$navBar, '#hiring', model)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('intro'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$author$project$Marketing$Logos$compoundLogo('compound-labs-logo'),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header__hidden-text')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$compound_labs_description(userLanguage))
																])),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header__typed-text')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header__cursor')
																]),
															_List_Nil)
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('about'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$created_first(userLanguage)),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.finance/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$liquidity_protocol(userLanguage))
																])),
															$elm$html$Html$text(
															$author$project$Strings$Translations$for_defi(userLanguage)),
															$elm$html$Html$text(', '),
															$elm$html$Html$text(
															$author$project$Strings$Translations$pioneered_opensource(userLanguage)),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://compound.finance/governance')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$protocol_governance(userLanguage))
																])),
															$elm$html$Html$text('. '),
															$elm$html$Html$text(
															$author$project$Strings$Translations$we_work_on_cross_chain(userLanguage)),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance/gateway-623f6f48d2b6')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$gateway(userLanguage))
																])),
															$elm$html$Html$text(', '),
															$elm$html$Html$text(
															$author$project$Strings$Translations$institutional_products(userLanguage)),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://medium.com/compound-finance/announcing-compound-treasury-for-businesses-institutions-83d4484fb82e')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$compound_treasury(userLanguage))
																])),
															$elm$html$Html$text('. ')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('investors'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$we_are_backed_by(userLanguage)),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://a16z.com/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$a16z(userLanguage))
																])),
															$elm$html$Html$text(', '),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.paradigm.xyz/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$paradigm(userLanguage))
																])),
															$elm$html$Html$text(', '),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.coinbase.com/ventures')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$coinbase_ventures(userLanguage))
																])),
															$elm$html$Html$text(', '),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.baincapital.com/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$bain_capital(userLanguage))
																])),
															$elm$html$Html$text(', '),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://polychain.capital/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$polychain_capital(userLanguage))
																])),
															$elm$html$Html$text(', '),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__base-text')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$and(userLanguage))
																])),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.dragonfly.xyz/')),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$dragonfly_capital(userLanguage))
																])),
															$elm$html$Html$text('.')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('press'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$reputation(userLanguage))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__columns')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__column')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__column-title')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$blog_posts(userLanguage))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://blog.compoundlabs.xyz/encumber/')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$h2,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__title')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$emcumber_blog(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$label,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__press-name')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text('Coburn Berry'),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__author-title')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(', '),
																							$elm$html$Html$text(
																							$author$project$Strings$Translations$staff_engineer(userLanguage)),
																							$elm$html$Html$text(' & ')
																						])),
																					$elm$html$Html$text('Mykel Pereira'),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__author-title')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(', '),
																							$elm$html$Html$text(
																							$author$project$Strings$Translations$vp_of_product(userLanguage))
																						])),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__date')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							' • ' + $author$project$Strings$Translations$june_2023(userLanguage))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__arrow mobile-hide')
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__column')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__column-title')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Strings$Translations$recent_press(userLanguage))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://decrypt.co/140424/crypto-lender-compound-deploys-ethereum-layer-2-arbitrum')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$h2,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__title')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$deploy_on_arbitrum(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$label,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__press-name')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$decrypt(userLanguage)),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__date')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							' • ' + $author$project$Strings$Translations$may_2023(userLanguage))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__arrow mobile-hide')
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://blockworks.co/news/will-defi-summer-return')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$h2,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__title')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$defi_summer_return(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$label,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__press-name')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$blockworks(userLanguage)),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__date')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							' • ' + $author$project$Strings$Translations$april_2023(userLanguage))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__arrow mobile-hide')
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.coindesk.com/video/compound-labs-founder-on-options-for-genesis-creditors/')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$h2,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__title')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$option_for_genesis(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$label,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__press-name')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$coindesk(userLanguage)),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__date')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							' • ' + $author$project$Strings$Translations$jan_2023(userLanguage))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__arrow mobile-hide')
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item'),
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('__blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.theblock.co/post/163788/defi-protocol-compound-says-multi-chain-comet-upgrade-is-ready-to-go')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$h2,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__title')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$multi_chain_comet(userLanguage))
																				])),
																			A2(
																			$elm$html$Html$label,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__press-name')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$Strings$Translations$the_block(userLanguage)),
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__title-section__date')
																						]),
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							' • ' + $author$project$Strings$Translations$aug_2022(userLanguage))
																						]))
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('press-item__arrow mobile-hide')
																		]),
																	_List_Nil)
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																]),
															_List_Nil)
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('team'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$our_team_experience(userLanguage))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('members-section')
												]),
											_List_fromArray(
												[
													$author$project$Marketing$Home$teamMembers(userLanguage),
													$author$project$Marketing$Home$teamMembers(userLanguage)
												]))
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$id('hiring'),
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('home-section__content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h1,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__header')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Strings$Translations$and_yes(userLanguage)),
															$elm$html$Html$text(' '),
															A2(
															$elm$html$Html$a,
															_Utils_ap(
																_List_fromArray(
																	[
																		$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
																	]),
																A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://jobs.lever.co/compound-2')),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$span,
																	_List_fromArray(
																		[
																			$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero-section__green-text')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(
																			$author$project$Strings$Translations$we_are_hiring(userLanguage))
																		]))
																]))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('content-section')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$author$project$Shared$Utils$CompoundHtmlAttributes$class('row')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$author$project$Shared$Utils$CompoundHtmlAttributes$class('col-sm-12 jobs')
																]),
															A2(
																$elm$core$List$concatMap,
																function (job) {
																	return _List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$author$project$Shared$Utils$CompoundHtmlAttributes$class('line')
																				]),
																			_List_Nil),
																			A2(
																			$elm$html$Html$a,
																			_Utils_ap(
																				_List_fromArray(
																					[
																						$author$project$Shared$Utils$CompoundHtmlAttributes$class('job-row'),
																						$author$project$Shared$Utils$CompoundHtmlAttributes$target('_blank')
																					]),
																				A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, job.iS)),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('job-section')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$h3,
																							_List_Nil,
																							_List_fromArray(
																								[
																									A2(
																									$elm$html$Html$span,
																									_List_fromArray(
																										[
																											$author$project$Shared$Utils$CompoundHtmlAttributes$class('job-section__title')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(job.iJ)
																										])),
																									A2(
																									$elm$html$Html$span,
																									_List_fromArray(
																										[
																											$author$project$Shared$Utils$CompoundHtmlAttributes$class('job-section__location')
																										]),
																									_List_fromArray(
																										[
																											$elm$html$Html$text(job.hw.h8)
																										]))
																								]))
																						])),
																					A2(
																					$elm$html$Html$div,
																					_List_fromArray(
																						[
																							$author$project$Shared$Utils$CompoundHtmlAttributes$class('job-section__arrow mobile-hide')
																						]),
																					_List_Nil)
																				]))
																		]);
																},
																A2(
																	$elm$core$List$sortBy,
																	function ($) {
																		return $.iJ;
																	},
																	jobs)))
														])),
													$author$project$Marketing$Home$pageFooter(userLanguage)
												]))
										]))
								]))
						]))
				]));
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$append = _String_append;
var $author$project$Marketing$Thesis$thumbnailCard = function (vmd) {
	return A2(
		$elm$html$Html$a,
		_Utils_ap(
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$class('thumbnail-card')
				]),
			A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 1, '/thesis/#' + vmd.dQ)),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$src('https://i.ytimg.com/vi/' + (vmd.dd + '/mqdefault.jpg'))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('thumbnail-card__episode-number label L2')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($elm$core$String$append, 'Episode ', vmd.dQ))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('thumbnail-card__title L4 heading heading--emphasized')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(vmd.iJ)
					]))
			]));
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Marketing$Thesis$allEpisodesSection = function (episodes) {
	var yearlyHtml = F2(
		function (year, eps) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('year-of-shows')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('episode-year')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('L4 heading heading--emphasized')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(year)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('long-gray-line')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('cards-list')
							]),
						A2($elm$core$List$map, $author$project$Marketing$Thesis$thumbnailCard, eps))
					]));
		});
	var sorted = $elm$core$List$reverse(episodes);
	var groupBy = F2(
		function (keyfn, list) {
			return A3(
				$elm$core$List$foldr,
				F2(
					function (x, acc) {
						return A3(
							$elm$core$Dict$update,
							keyfn(x),
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$map(
									$elm$core$List$cons(x)),
								A2(
									$elm$core$Basics$composeR,
									$elm$core$Maybe$withDefault(
										_List_fromArray(
											[x])),
									$elm$core$Maybe$Just)),
							acc);
					}),
				$elm$core$Dict$empty,
				list);
		});
	var findYear = function (episode) {
		var maybeYear = $elm$core$List$head(
			$elm$core$List$reverse(
				A2($elm$core$String$split, '/', episode.fE)));
		if (!maybeYear.$) {
			var year = maybeYear.a;
			return '20' + year;
		} else {
			return '';
		}
	};
	var yearlies = $elm$core$List$reverse(
		$elm$core$Dict$values(
			A2(
				$elm$core$Dict$map,
				yearlyHtml,
				A2(groupBy, findYear, sorted))));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('all-episodes guttered')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('L1 heading heading--emphasized')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('All Episodes')
						]))
				]),
			yearlies));
};
var $author$project$Marketing$Thesis$InputEmailChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Marketing$Thesis$SubmitEmailGrabber = {$: 4};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$Shared$Utils$CompoundHtmlAttributes$placeholder = function (placeholderValue) {
	return A2($elm$html$Html$Attributes$attribute, 'placeholder', placeholderValue);
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Marketing$Thesis$emailGrabber = function (emailStatus) {
	var _v0 = function () {
		switch (emailStatus) {
			case 1:
				return _Utils_Tuple2('error', 'Invalid Email');
			case 0:
				return _Utils_Tuple2('success', 'Success');
			default:
				return _Utils_Tuple2('', 'Subscribe');
		}
	}();
	var emailStatusClass = _v0.a;
	var buttonText = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('inbound-email-grabber')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Signup for the latest insights and updates from Compound')
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('email-form'),
						$elm$html$Html$Events$onSubmit($author$project$Marketing$Thesis$SubmitEmailGrabber)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('input-padder')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('label L2 label--secondary ' + emailStatusClass),
										$author$project$Shared$Utils$CompoundHtmlAttributes$placeholder('Enter Email'),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $author$project$Marketing$Thesis$InputEmailChanged))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('status-symbol')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('button'),
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('pill-button__text pill-button label L2 label--secondary'),
								$elm$html$Html$Events$onClick($author$project$Marketing$Thesis$SubmitEmailGrabber)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(buttonText)
							]))
					]))
			]));
};
var $author$project$Marketing$Thesis$channelLinks = function (size) {
	var ytLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://www.youtube.com/channel/UCzCqNtFfs1DItxK-3bR2ovw');
	var spotifyLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://open.spotify.com/show/5UAJPO8WhbSiVPIjyd5Phj');
	var appleLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://podcasts.apple.com/us/podcast/compound-thesis/id1649643491');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('flex-and-gap listen-on-links')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('listen-on-title gray-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(size) + ' Episodes')
					])),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					spotifyLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('spotify')
						])),
				_List_Nil),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					ytLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('youtube')
						])),
				_List_Nil),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					appleLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('apple')
						])),
				_List_Nil)
			]));
};
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $author$project$Marketing$Thesis$player = function (episodeId) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('video-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$iframe,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('thesis-player'),
						$author$project$Shared$Utils$CompoundHtmlAttributes$id('ytplayer'),
						$author$project$Shared$Utils$CompoundHtmlAttributes$src('https://www.youtube.com/embed/' + (episodeId + '?listType=playlist&list=PLSIg4m5N06VJ6Ye0IRkZ0Y2SH1HocJ-hI'))
					]),
				_List_Nil)
			]));
};
var $author$project$Marketing$Thesis$hero = function (episodes) {
	var sorted = $elm$core$List$reverse(episodes);
	var size = $elm$core$List$length(episodes);
	var heroEpisodeId = function () {
		var _v0 = $elm$core$List$head(sorted);
		if (_v0.$ === 1) {
			return 'f7lx3DQYak4';
		} else {
			var latestEpisode = _v0.a;
			return latestEpisode.dd;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero guttered')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$src('/images/thesis_logo.svg'),
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero__thesis-logo')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('left-side-drip')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('label L2 label--secondary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('INSTITUTIONAL INSIGHTS')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('long-gray-line')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('right-side-drip')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('long-gray-line')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$author$project$Shared$Utils$CompoundHtmlAttributes$class('label L2 label--secondary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('VIDEO SERIES')
									]))
							])),
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$author$project$Shared$Utils$CompoundHtmlAttributes$class('mission-statement heading heading--emphasized L2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Compound Thesis is a series discussing the intersection of crypto and the capital markets with industry thought leaders.')
							])),
						$author$project$Marketing$Thesis$channelLinks(size)
					])),
				$author$project$Marketing$Thesis$player(heroEpisodeId),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('padded')
						]),
					A2(
						$author$project$Shared$Utils$CompoundHtmlAttributes$href,
						1,
						'/thesis#' + $elm$core$String$fromInt(size))),
				_List_fromArray(
					[
						$elm$html$Html$text('More about this episode')
					]))
			]));
};
var $author$project$Marketing$Thesis$indexView = F3(
	function (userLanguage, videos, emailStatus) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$id('marketing-thesis')
				]),
			_List_fromArray(
				[
					$author$project$Marketing$Thesis$hero(videos),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('gradient-bg')
						]),
					_List_fromArray(
						[
							$author$project$Marketing$Thesis$allEpisodesSection(videos)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('checker-bg')
						]),
					_List_fromArray(
						[
							$author$project$Marketing$Thesis$emailGrabber(emailStatus)
						]))
				]));
	});
var $author$project$Marketing$Thesis$ShowAbout = {$: 2};
var $author$project$Marketing$Thesis$ShowTranscript = {$: 3};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {d: index, er: match, ii: number, fg: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{hv: false, $8: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$regex$Regex$split = _Regex_splitAtMost(_Regex_infinity);
var $author$project$Marketing$Thesis$splitter = F2(
	function (pattern, operand) {
		var maybeRegex = $elm$regex$Regex$fromString(pattern);
		var regex = A2($elm$core$Maybe$withDefault, $elm$regex$Regex$never, maybeRegex);
		return A2($elm$regex$Regex$split, regex, operand);
	});
var $author$project$Marketing$Thesis$aboutOrTranscript = F2(
	function (model, vmb) {
		var parsedTranscript = A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('transcript-line')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(x)
						]));
			},
			A2($author$project$Marketing$Thesis$splitter, '\\[\\d\\d:\\d\\d:\\d\\d\\]', vmb.iQ));
		var parsedAbout = A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('transcript-line')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(x)
						]));
			},
			A2($author$project$Marketing$Thesis$splitter, '\\n', vmb.hk));
		var _v0 = function () {
			var _v3 = model.b0;
			if (!_v3) {
				return _Utils_Tuple2(
					_Utils_Tuple2('active', ''),
					_Utils_Tuple2('selected', 'hidden'));
			} else {
				return _Utils_Tuple2(
					_Utils_Tuple2('', 'active'),
					_Utils_Tuple2('hidden', 'selected'));
			}
		}();
		var _v1 = _v0.a;
		var aboutButtonClass = _v1.a;
		var transcriptButtonClass = _v1.b;
		var _v2 = _v0.b;
		var aboutClass = _v2.a;
		var transcriptClass = _v2.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Shared$Utils$CompoundHtmlAttributes$class('about-or-transcript')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('heading L1 heading--emphasized')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(vmb.iJ)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('about-or-transcript__airing-info flex-and-gap')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('gray-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Episode ' + vmb.dQ)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('gray-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(vmb.fE)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('about-or-transcript__buttons')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('about-button L3 heading heading--emphasized ' + aboutButtonClass),
									$elm$html$Html$Events$onClick($author$project$Marketing$Thesis$ShowAbout)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('About')
								])),
							A2(
							$elm$html$Html$h4,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('transcript-button L3 heading heading--emphasized ' + transcriptButtonClass),
									$elm$html$Html$Events$onClick($author$project$Marketing$Thesis$ShowTranscript)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Transcript')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('about body L3 ' + aboutClass)
						]),
					parsedAbout),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('transcript body L3 ' + transcriptClass)
						]),
					parsedTranscript)
				]));
	});
var $author$project$Marketing$Thesis$bioCard = F2(
	function (image, bio) {
		var _v0 = A2($author$project$Marketing$Thesis$splitter, '\\n', bio);
		if (!_v0.b) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			if ((_v0.a === '') && (!_v0.b.b)) {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			} else {
				var name = _v0.a;
				var titles_and_such = _v0.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('thesis-bio')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$src(image)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('name-and-bio')
								]),
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$author$project$Shared$Utils$CompoundHtmlAttributes$class('name-and-bio__name heading L4 heading--emphasized')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(name)
											]))
									]),
								A2(
									$elm$core$List$map,
									function (x) {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('name-and-bio__titles-and-such')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(x)
												]));
									},
									titles_and_such)))
						]));
			}
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Marketing$Thesis$Models$findEpisode = F2(
	function (episodes, episodeNumber) {
		var entry = A2(
			$elm$core$List$filter,
			function (x) {
				return _Utils_eq(x.dQ, episodeNumber);
			},
			episodes);
		return $elm$core$List$head(entry);
	});
var $author$project$Marketing$Thesis$listenOnLinks = function (b) {
	var ytLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, 'https://youtu.be/' + b.dd);
	var spotifyLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, b.iC);
	var appleLink = A2($author$project$Shared$Utils$CompoundHtmlAttributes$href, 2, b.hn);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$class('flex-and-gap listen-on-links')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$author$project$Shared$Utils$CompoundHtmlAttributes$class('listen-on-title gray-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('available On')
					])),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					spotifyLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('spotify')
						])),
				_List_Nil),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					ytLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('youtube')
						])),
				_List_Nil),
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					appleLink,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$class('apple')
						])),
				_List_Nil)
			]));
};
var $author$project$Marketing$Thesis$showView = F4(
	function (userLanguage, model, episodeNumber, vids) {
		var everything = function () {
			var _v0 = A2($author$project$Marketing$Thesis$Models$findEpisode, vids, episodeNumber);
			if (_v0.$ === 1) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$id('marketing-thesis')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('')
								]))
						]));
			} else {
				var b = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Shared$Utils$CompoundHtmlAttributes$id('marketing-thesis')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('hero guttered')
								]),
							_List_fromArray(
								[
									$author$project$Marketing$Thesis$player(b.dd)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('episode-info guttered')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$author$project$Shared$Utils$CompoundHtmlAttributes$class('bios')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$author$project$Shared$Utils$CompoundHtmlAttributes$class('gray-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('SPEAKERS')
												])),
											A2($author$project$Marketing$Thesis$bioCard, b.h1, b.h0),
											A2($author$project$Marketing$Thesis$bioCard, b.hZ, b.hY),
											A2($author$project$Marketing$Thesis$bioCard, b.hX, b.hW),
											$author$project$Marketing$Thesis$listenOnLinks(b)
										])),
									A2($author$project$Marketing$Thesis$aboutOrTranscript, model, b)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('gradient-bg')
								]),
							_List_fromArray(
								[
									$author$project$Marketing$Thesis$allEpisodesSection(vids)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Shared$Utils$CompoundHtmlAttributes$class('checker-bg')
								]),
							_List_fromArray(
								[
									$author$project$Marketing$Thesis$emailGrabber(model.aV)
								]))
						]));
			}
		}();
		return everything;
	});
var $author$project$Marketing$Thesis$Models$bvm = function (fields) {
	if (((((((((((((fields.b && fields.b.b) && fields.b.b.b) && fields.b.b.b.b) && fields.b.b.b.b.b) && fields.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b.b.b.b.b) && fields.b.b.b.b.b.b.b.b.b.b.b.b.b.b) {
		var episodeNumber = fields.a;
		var _v1 = fields.b;
		var airDate = _v1.a;
		var _v2 = _v1.b;
		var title = _v2.a;
		var _v3 = _v2.b;
		var transcript = _v3.a;
		var _v4 = _v3.b;
		var about = _v4.a;
		var _v5 = _v4.b;
		var hostImage = _v5.a;
		var _v6 = _v5.b;
		var hostBio = _v6.a;
		var _v7 = _v6.b;
		var guestImage = _v7.a;
		var _v8 = _v7.b;
		var guestBio = _v8.a;
		var _v9 = _v8.b;
		var guest2Image = _v9.a;
		var _v10 = _v9.b;
		var guest2Bio = _v10.a;
		var _v11 = _v10.b;
		var youtubeLink = _v11.a;
		var _v12 = _v11.b;
		var spotifyLink = _v12.a;
		var _v13 = _v12.b;
		var appleLink = _v13.a;
		return $elm$core$Maybe$Just(
			{hk: about, fE: airDate, hn: appleLink, dQ: episodeNumber, hW: guest2Bio, hX: guest2Image, hY: guestBio, hZ: guestImage, h0: hostBio, h1: hostImage, iC: spotifyLink, iJ: title, iQ: transcript, dd: youtubeLink});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$Marketing$Thesis$Models$toVideoMetadata = function (sheetsResponse) {
	if (sheetsResponse.$ === 1) {
		return _List_Nil;
	} else {
		var sheet = sheetsResponse.a;
		return A2($elm$core$List$filterMap, $author$project$Marketing$Thesis$Models$bvm, sheet.ha);
	}
};
var $author$project$Marketing$Thesis$view = F2(
	function (userLanguage, model) {
		var vidData = $author$project$Marketing$Thesis$Models$toVideoMetadata(model.dc);
		var _v0 = model.fB;
		if (_v0.$ === 1) {
			return A3($author$project$Marketing$Thesis$indexView, userLanguage, vidData, model.aV);
		} else {
			var episodeNumber = _v0.a;
			return A4($author$project$Marketing$Thesis$showView, userLanguage, model, episodeNumber, vidData);
		}
	});
var $author$project$Main$viewPage = function (model) {
	var page = model.a$;
	var isSafari = model.bF;
	var userLanguage = model.bU;
	if (!page.$) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Main$marketingHomeTranslator,
				A3($author$project$Marketing$Home$view, userLanguage, isSafari, model.ch))
			]);
	} else {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$map,
				$author$project$Main$marketingCommonTranslator,
				A3($author$project$Marketing$CommonViews$pageHeader, model.bU, model.a$, model.c0)),
				A2(
				$elm$html$Html$map,
				$author$project$Main$marketingThesisTranslator,
				A2($author$project$Marketing$Thesis$view, userLanguage, model.aI)),
				$author$project$Marketing$CommonViews$pageFooter(model.bU)
			]);
	}
};
var $author$project$Main$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Shared$Utils$CompoundHtmlAttributes$id('main')
			]),
		$author$project$Main$viewPage(model));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{h3: $author$project$Main$init, iG: $author$project$Main$subscriptions, iR: $author$project$Main$update, iT: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (path) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (language) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (isSafari) {
							return $elm$json$Json$Decode$succeed(
								{bF: isSafari, ee: language, eU: path});
						},
						A2($elm$json$Json$Decode$field, 'isSafari', $elm$json$Json$Decode$bool));
				},
				A2($elm$json$Json$Decode$field, 'language', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'path', $elm$json$Json$Decode$string)))(0)}});}(this));