export function getBlurParamsForSection(lhsc) {
  const rect = lhsc.getBoundingClientRect();
  // when viewport height is bigger then section height
  // say content height is 900, rect.top is -400, when rect.top is < 0 and > -900 then blur transition from 0rem to 0.4rem, opacity from 1 to 0.5
  if (window.innerHeight >= rect.height) {
    if (rect.top < 0 && rect.top > -rect.height) {
      const blur = ((0 - rect.top) / rect.height) * 0.4;
      return [`blur(${blur}rem)`, 1 - ((0 - rect.top) / rect.height) * 0.5];
    } else {
      return ['blur(0rem)', 1];
    }
  } else {
    // when viewport height is smaller then section height
    // rect.top is negative number here, since it's scrolled away. e,g. rect.top is -400 and overFlowHeight is -300 and rect.height is 900, then when rect.top is < -300 and > -900 we transition blur from 0rem to 0.4rem, opacity from 1 to 0.5
    const overFlowHeight = rect.height - window.innerHeight;
    if (rect.top < -overFlowHeight && rect.top > -rect.height) {
      const blur = ((0 - rect.top - overFlowHeight) / (rect.height - overFlowHeight)) * 0.4;
      return [`blur(${blur}rem)`, 1 - ((0 - rect.top - overFlowHeight) / (rect.height - overFlowHeight)) * 0.5];
    } else {
      return ['blur(0rem)', 1];
    }
  }
}

export function getAllTeamMembersWidth(membersPhotos) {
  return Array.from(membersPhotos).reduce((acc, m) => m.clientWidth + acc, 0);
}

export function langFromURL(url, navigatorLanguage) {
  // Most locales are of the form: "en_US", "es_MX", "fr_FR", etc...
  // However some have language subdialect specifiers like: "zh-Hant_US", "zh-Hant-HK", etc...
  const paramLocale = url.searchParams.get('locale');
  const localeMatch = /^[a-z]{2}.*[_][A-Z]{2}/.exec(paramLocale);
  const secondaryLocaleMatch = /^[a-z]{2}.*[-][A-Z]{2}/.exec(paramLocale); // Some people (imToken) use the other form of locale :(

  let localeLangOnly = null;
  if (localeMatch) {
    localeLangOnly = localeMatch[0].substring(0, 2);
  } else if (secondaryLocaleMatch) {
    localeLangOnly = secondaryLocaleMatch[0].substring(0, 2);
  }

  const domainMatch = /^(es|en|zh)[-.]/.exec(url.host);
  const domainLanguage = domainMatch ? domainMatch[1] : null;
  return localeLangOnly || domainLanguage || navigatorLanguage || 'en';
}
